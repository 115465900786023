<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.instrumento.titulos.historico')"
      sem-registros
      sem-botao-ok
      sem-filtro
    />
    <div class="mt-1 mb-4">
      <v-chip
        class="mr-1"
        small
      >
        Código: {{ badges.codigo }}
      </v-chip>
      <v-chip
        class="mr-1"
        small
      >
        Tipo Instrumento: {{ badges.tipoInstrumento }}
      </v-chip>
      <v-chip
        class="mr-1"
        small
      >
        Modelo: {{ badges.modelo }}
      </v-chip>
      <v-chip
        class="mr-1"
        small
      >
        Marca: {{ badges.marca }}
      </v-chip>
      <v-chip
        class="mr-1"
        small
      >
        Participante: {{ badges.participante }}
      </v-chip>
    </div>
    <tabela-padrao-prime-vue
      v-model="tabela.selecionados"
      :mostrar-acoes="false"
      class="mt-2"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      selecionar-colunas
      sem-selecionar-todos
      sem-paginacao
      ajustar-altura-linha
      :mostrar-seletor="false"
      row-group-mode="subheader"
      group-rows-by="ordemServico.codigo"
      sort-mode="single"
      sort-field="ordemServico.codigo"
      :sort-order="1"
      :expandable-row-groups="true"
      :expanded-row-groups="expandedRowGroups"
    >
      <template
        v-slot:agrupadorLinhas="{ slotProps }"
        class="historico-agrupador"
      >
        <span>{{ $t('modulos.instrumento.historico.ordem_servico') }}: </span>
        <span>{{
          slotProps.data.ordemServico.codigo
            ? slotProps.data.ordemServico.codigo
            : 'Sem Ordem de Serviço'
        }}</span>
      </template>
      <template v-slot:acao="{ slotProps }">
        <span>
          {{ slotProps.data.acao }}
        </span>
        <span v-if="slotProps.data.envioMaterial.codigo">
          - {{ slotProps.data.envioMaterial.codigo }}</span>
      </template>
    </tabela-padrao-prime-vue>
    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="voltar"
      >
        <v-icon>$mdiArrowLeft</v-icon>
        {{ $t('geral.botoes.voltar') }}
      </botao-padrao>
    </div>
  </div>
</template>

<script>
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import InstrumentoService from '@common/services/cadastros/InstrumentoService.js';
import moment from 'moment';
export default {
  components: {
    CabecalhoPagina,
  },
  props: ['id'],
  data() {
    return {
      badges: {
        codigo: '',
        tipoInstrumento: '',
        modelo: '',
        marca: '',
        participante: '',
      },
      expandedRowGroups: [],
      retornoUrl: null,
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'dataHora',
            text: this.$t('modulos.instrumento.historico.dataHora'),
            sortable: false,
            formatter: (v) => {
              if (v) return this.formatDateTime(v);
            },
          },
          {
            value: 'acao',
            text: this.$t('modulos.instrumento.historico.acao'),
            sortable: false,
          },
          // {
          //   value: 'envioMaterial.codigo',
          //   text: this.$t('modulos.instrumento.historico.envioMaterial'),
          //   sortable: false,
          //   formatter: (envioMaterial) => {
          //     return envioMaterial ? envioMaterial : '';
          //   },
          // },
          {
            value: 'ordemServico.codigo',
            text: this.$t('modulos.instrumento.historico.ordem_servico'),
            sortable: false,
            formatter: (os) => {
              return os ? os : '';
            },
          },
          {
            value: 'usuarioLogado.nome',
            text: this.$t('modulos.instrumento.historico.usuario'),
            sortable: false,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },
  created(){
    this.retornoUrl = this.$route.query.retorno;
  },
  mounted() {
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.instrumento.titulos.historico')
    );
    if (this.id) this.buscarHistorico(this.id);
  },
  methods: {
    buscarHistorico: function (id) {
      InstrumentoService.buscarHistorico(id).then((response) => {
        const {
          tipoInstrumento,
          participante,
          modeloInstrumento,
          marcaInstrumento,
          instrumento,
          detalhes,
        } = response.data;
        this.badges.codigo = instrumento.codigo;
        this.badges.tipoInstrumento = tipoInstrumento.nome;
        this.badges.modelo = modeloInstrumento.nome;
        this.badges.marca = marcaInstrumento.nome;
        this.badges.participante = participante.nome;
        this.tabela.dados = detalhes;
        this.tabela.quantidadeItens = detalhes.length;
      });
    },
    formatDateTime: function (datetime) {
      return moment(datetime).format('DD/MM/YYYY - HH[h]mm');
    },
    voltar: function () {
      if(this.retornoUrl === 'operacao') {
        this.$router.push({ name: 'operacao' })
      }else{
        this.$router.push({ name: 'instrumento' });
      }
    },
  },
};
</script>

<style scoped>

</style>
